import Rest from '../Rest'

export default class PlanBillingService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/billing'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  getPlanUsageList(planBillingId: number) {
    return this.get(`/${planBillingId}/plan_usage`)
  }

  savePlanUsage(planBillingId: number, id: number, payload: any){
    const form={data_form :payload}
    return this.put(`/${planBillingId}/plan_usage/${id}`, form)
  }

  deletePlanUsage(planBillingId: number, id: number) {
    return this.delete(`/${planBillingId}/plan_usage/${id}`).then(
      (data) => {
        return data
      }
    )
  }

  savePlanUsageLimits(planBillingId: number, payload: any) {
    const form={data_form :payload}
    return this.post(`/${planBillingId}/plan_usage`, form)
  }

  getPlanUsageByTarget(target: string) {
    return this.get(`/plan_usage?target=${target}`)
  }

   //Get plan subscription
   getMySubscriptions() {
    return this.get(`/my_subscriptions`)
  }

}

