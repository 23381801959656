var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.planList, function (plan) {
      return _c(
        "div",
        { key: plan.id },
        [
          _c(
            "vs-table",
            {
              attrs: {
                data: plan.usage,
                "no-data-text": _vm.$t("nenhum-registro-encontrado"),
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("h4", { staticClass: "p-2" }, [
                          _vm._v(_vm._s(plan.name) + " "),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "default",
                    fn: function (ref) {
                      var data = ref.data
                      return _vm._l(data, function (tr, i) {
                        return _c(
                          "vs-tr",
                          { key: data[i], style: _vm.rowStyle(data[i]) },
                          [
                            _c("vs-td", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("plan.limit." + data[i].type.name)
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("vs-td", [
                              _vm._v(" " + _vm._s(data[i].start_at_fmt) + " "),
                            ]),
                            _c("vs-td", [
                              _vm._v(" " + _vm._s(data[i].finish_at_fmt) + " "),
                            ]),
                            _c("vs-td", [
                              _vm._v(" " + _vm._s(data[i].total) + " "),
                            ]),
                            _c("vs-td", [
                              _vm._v(" " + _vm._s(data[i].count) + " "),
                            ]),
                          ],
                          1
                        )
                      })
                    },
                  },
                ],
                null,
                true
              ),
            },
            [
              _c(
                "template",
                { slot: "thead" },
                [
                  _c("vs-th", { attrs: { width: "30%" } }, [
                    _vm._v(_vm._s(_vm.$t("plan.limits"))),
                  ]),
                  _c("vs-th", { attrs: { width: "20%" } }, [
                    _vm._v(_vm._s(_vm.$t("plan.start"))),
                  ]),
                  _c("vs-th", { attrs: { width: "20%" } }, [
                    _vm._v(_vm._s(_vm.$t("plan.finish"))),
                  ]),
                  _c("vs-th", { attrs: { width: "10%" } }, [
                    _vm._v(_vm._s(_vm.$t("plan.total"))),
                  ]),
                  _c("vs-th", { attrs: { width: "10%" } }, [
                    _vm._v(_vm._s(_vm.$t("plan.usage"))),
                  ]),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }